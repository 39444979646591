<template>
    <div class="dashboard-main">
        <h1 class="dashboard-main-heading" v-html="resource[lang].shared_matrices" />
        
        <div class="search grid">
            <InputText :label="resource[lang].enter_search_term" v-model="search_term" />
            <Button color="primary" :label="resource[lang].search" @click="search" />
        </div>

        <DashboardListItem v-for="matrix in matrices" :key="matrix.id" :matrix="matrix" />

        <div class="not-found" v-if="matrices.length == 0">
            <p class="text" v-html="resource[lang].no_matrices_available" />
        </div>

        <Button 
            v-if="!all_matrices" 
            class="show-more" 
            color="primary" 
            :label="resource[lang].show_more" 
            @click="getMySharedMatrices" 
        />
    </div>
</template>

<script>
import resource from '@/assets/resources/dashboard-resource'

import Button from '@/components/basic/Button'
import DashboardListItem from '@/components/DashboardListItem'
import InputText from '@/components/basic/InputText'

import { promiseGetRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        Button,
        DashboardListItem,
        InputText
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            matrices: [],
            matrix_page: 1,
            all_matrices: true,

            // search
            search_term: '',
            applied_search_term: '',

            resource
        }
    },
    created() {
        this.getMySharedMatrices()
    },
    methods: {
        // Requests
        getMySharedMatricesRequest() {
            let route = `my-shared-matrices?page=${this.matrix_page}`;

            if (this.applied_search_term) {
                route = `${route}&search=${this.applied_search_term}`;
            }

            return promiseGetRequest(route);
        },

        // Actions
        search() {
            this.applied_search_term = this.search_term;

            this.matrices = [];
            this.matrix_page = 1;

            this.getMySharedMatrices();
        },

        // Functions
        async getMySharedMatrices() {
            this.$store.dispatch('loading', true)
            const response = await this.getMySharedMatricesRequest();
            this.$store.dispatch('loading', false)
            this.matrices = [...this.matrices, ...response.data]
            this.matrix_page += 1
            if (response.data.length < 10) {
                this.all_matrices = true;
            } else {
                this.all_matrices = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>

.dashboard-main > * + * {
    margin-top: 1rem;
}

@media (max-width: 40rem) {
    button {
        width: 100%;
    }
}

</style>